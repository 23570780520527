import React from 'react';
import { SimpleShowLayout, TextField } from 'react-admin';

const InfoTab = () => {
  return (
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField label="Legal Name" source="legal_name" />
      <TextField label="Status" source="status" />
    </SimpleShowLayout>
  );
};

export default InfoTab;
