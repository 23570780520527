import React from 'react';
import { PdfPreview } from '@medely/web-components';
import { DateField, FunctionField } from 'react-admin';
import ImageField from 'components/ImageField';
import { ProfessionalQualificationAttribute } from 'resources/professional_qualification_attributes/utils';
import { JsonField } from 'react-admin-json-view';
import StateField from 'components/StateField';
import { AttachmentField } from 'components/AttachmentField';

const ProfessionalQualificationAttributeField = () => (
  <FunctionField
    label="Value"
    render={(record) => {
      const pqa = new ProfessionalQualificationAttribute(record);
      if (pqa.isImage) {
        return <ImageField record={record} source="value" />;
      } else if (pqa.isPdf) {
        const url = new URL(record.value);
        const filename = url.pathname.split('/').pop();

        return (
          <>
            <PdfPreview src={record.value} />
            <a href={record.value} target={'_blank'} download={filename}>
              Download PDF
            </a>
          </>
        );
      } else if (pqa.isUnsignedDocument) {
        return <span>Document has not been signed.</span>;
      } else if (pqa.isAttachment) {
        return <AttachmentField record={record} source="value" />;
      } else if (pqa.isJson) {
        return <JsonField record={record} source="value" />;
      } else if (pqa.isState) {
        return <StateField record={record} source="value" />;
      } else if (pqa.isDate) {
        return <DateField record={record} source="value" />;
      }
      return String(pqa.value);
    }}
  />
);

export default ProfessionalQualificationAttributeField;
