import EnumSelectInput from 'components/Enum/EnumSelectInput';
import React from 'react';
import { Edit, TextInput, SimpleForm } from 'react-admin';

const AgenciesEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        <TextInput label="Name" source="name" />
        <TextInput label="Legal Name" source="legal_name" />
        <EnumSelectInput label="Status" source="status" />
      </SimpleForm>
    </Edit>
  );
};

export default AgenciesEdit;
