import React from 'react';
import { ENUMS } from '../Enum/enums';
import AdministratorInput from './AdministratorInput';

const AccountManagerInput = (props) => <AdministratorInput {...props} />;

AccountManagerInput.defaultProps = {
  label: 'Account Manager',
  source: 'search.administrator_id',
  filter: { permission_group_roles: [ENUMS.PermissionGroup.role.account_manager] },
  sort: { field: 'id', order: 'ASC' },
};

export default AccountManagerInput;
