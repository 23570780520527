import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PhoneInput from 'components/PhoneInput';
import StateInput from 'components/StateInput';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const AgenciesCreate = () => {
  const initialValues = {
    phone_number: '',
  };
  return (
    <Create redirect="show">
      <SimpleForm record={initialValues}>
        <TextInput source="name" />
        <TextInput source="legal_name" />
        <TextInput source="address_first" />
        <TextInput source="address_second" />
        <TextInput source="address_city" />
        <TextInput source="address_zip" />
        <StateInput />
        <PhoneInput source="phone_number" />
        <EnumSelectInput label="Status" source="status" />
      </SimpleForm>
    </Create>
  );
};

export default AgenciesCreate;
