import React from 'react';
import { TextField, Datagrid, SimpleShowLayout, Pagination, ReferenceManyField } from 'react-admin';
import LinkField from 'components/LinkField';

const LocationsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="AgencyLocation"
      target="agency_id"
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="Agency Location" source="id" />
        <TextField label="Status" source="status" />
        <LinkField label="Location" source="location.name" />
        <TextField label="Market" source="location.market.display_name" />
        <TextField label="State" source="location.state.name" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(LocationsTab);
