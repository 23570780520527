import React from 'react';
import {
  TextField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SimpleShowLayout,
  Pagination,
} from 'react-admin';
import LinkField from 'components/LinkField';

const AccountsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      label={false}
      reference="Account"
      pagination={<Pagination />}
      target="agency_id"
      filter={{ account_type: 'agency' }}
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="id" typename="Account" />
        <TextField label="Name" source="name" />
        <TextField label="Email" source="email" />
        <TextField label="Title" source="title" />
        <FunctionField
          label="Permission Groups"
          render={(record) =>
            record.permission_groups?.map(({ display_name }) => display_name).join(', ')
          }
        />
        <TextField label="Status" source="status" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default AccountsTab;
