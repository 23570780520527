import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, BooleanField } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';

const Work_historiesList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <LinkField source="professional_profile.id" />
      <TextField source="organization_name" />
      <TextField source="start_date" />
      <TextField source="end_date" />
      <BooleanField source="currently_work_here" />
    </Datagrid>
  </List>
);

Work_historiesList.query = gql`
  query GET_LIST($input: WorkHistoryQueryInput) {
    _workHistoriesMeta(input: $input) {
      count
    }
    workHistories(input: $input) {
      id
      clinical
      description
      employment_type
      end_date
      position {
        display_name
      }
      position_title
      organization_name
      location
      organization_name
      position_title
      professional_profile {
        id
      }
      start_date
      state {
        id
        name
      }
      unit
    }
  }
`;

export default Work_historiesList;
